<template>
    <!-- 宁兴 -->
    <div id="CustomerDZMotor">
      <!-- CustomerDZMotor头部内容 -->
      <div class="CustomerDZMotorHeader">
        <!-- 头部组件 -->
        <Header />
      </div>
      <!-- CustomerDZMotor中间内容 -->
      <div class="CustomerDZMotorCenter">
        <!-- 客户介绍 -->
        <div class="contentOne">
          <div class="titleOne">客户介绍</div>
          <div class="conInfo">
            <img src="~assets/img/CustomerNX/banner@2x.png" />
            <div class="infoOne">
              &#160;&#160;&#160;&#160;&#160;舟山宁兴船舶修造有限公司成立于 2020 年，自公司创立之初，逐步确立了“小、精、专”的经营发展方向，明确了以液货船、冷藏船、挖泥船、拖轮等特种船舶为特色的产品定位<br /><br />
              &#160;&#160;&#160;&#160;&#160;公司始终对标行业标杆，不断向现代造船模式迈进，提出公司“通过生产模式现代化、经营管理数字化的升级改造，打造‘绿色’、‘高效’的现代船舶制造基地” 发展愿景。
             
            </div>
          </div>
        </div>
        <!-- 项目内容 -->
        <div class="contentTwo">
          <div class="titleTwo">项目内容</div>
          <div class="titleT">
            公司结合现代化造船模式和理念，以计划的制定和跟踪考核为核心打造 SPM生产管理系统，生产三维可视化实时监控系统为核心，经 营状态、生产状态、品控管理、安环管理、能源管理、设备管理、人员管理、智能监控全方位企业 数据化管理生态系统，已于2023年至今已完成生产状态、品控管理、安环管理、能源管理等软件建设，即将启动设备管理、人员管理、智能监控等软件建设。实现生产过程跟踪反馈、监控、考核及管理。
本项目的实施为全面掌握生产建造进度，高效缩短造船周期提供数据支撑，有效解决船舶生产中过程管理难的问题，为企业生产过程管控保驾护航。
          </div>
        </div>
    
      <!-- 项目成效 -->
      <div class="contentTwo" style="margin-top: 80px;">
                <div class="titleTwo">项目成效</div>
                <div class="titleT">
                    1.强化船舶建造企业安全生产可视化监督能力。主动联系和组织相关部门、专家，分阶段、有重点地对辖区内船舶修造业开展全面检查，严肃查处安全生产违法行为，通过工业互联网可视化技术进行实时监控和督察，促使企业各项安全生产制度落实到位和落实主体责任。<br />
2.打破信息孤岛效应。互联网新一代信息技术可以为供应链管理提供信息交换和共享的平台，以大大降低供应链管理的成本和管理效率。为了切实保障船舶建造进度环环相扣，就必须在修造过程中的价格、使用性能、质量等及时到位，当出现了一些建造信息变更的情况，一定要在及时通知上下游供应链，调整供货时间，以避免出现经济浪费。<br />
<el-icon class="el-icon-trophy" style="color: #ffc24c;font-size: 18px;"></el-icon> 舟山市2023年工业互联网标杆项目
               
</div>
            </div>

            <!-- 项目概况 -->
            <div class="contentThree">
                <!-- <div class="titleThree">项目概况</div> -->
                <ul class="imgContent">
                    <li v-for="(item, index) in imgContents" :key="index" >
                        <img :src="item.Imgs" style="width: 550px;height: 324px;" />
                        <!-- <div class="imgBottom">{{ item.textPro }}</div> -->
                    </li>
                </ul>
            </div>
        </div>
      <!-- 底部内容 -->
      <Footer />
      <!-- 回到顶部 -->
      <el-backtop
        ><img
          src="~assets/img/CustomerDZMotor/icon-一键置顶@2x.png"
          alt="icon-回到顶部@2x.png"
      /></el-backtop>
    </div>
  </template>
  <script>
  import Header from "components/Header/dsHeader";
  import Footer from "components/Footer/dsFooter";
  export default {
    name: "CustomerDZMotor",
    data() {
      return {
        imgContents: [
          {
            Imgs: require("assets/img/board/nx/01.png"),
            textPro: ""
          },{
            Imgs: require("assets/img/board/nx/02.jpg"),
            textPro: ""
          }
        ],
       
      };
    },
    mounted() {  
      document.title = '岱山汽船配工业互联网平台';   
  },
    components: {
      Header,
      Footer
    }
  };
  </script>
<style lang="less" scope>
#CustomerDZMotor {
  width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;

  // CusCustomerDZMotor头部样式
  .CustomerDZMotorHeader {
    width: 1583px;
    height: 620px;
    background: url("~assets/img/CustomerNX/公司@2x.png") no-repeat;
    background-size: 1583px 620px;
    background-position: center;
    margin: 0 auto;
  }

  // CustomerDZMotor中间内容样式
  .CustomerDZMotorCenter {
    width: 1220px;
    margin: 80px auto 101px;

    // 客户介绍
    .contentOne {
      .titleOne {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }

      .conInfo {
        margin-top: 30px;
        display: flex;

        img {
          width: 560px;
          height: 343px;
        }

        .infoOne {
          width: 590px;
          height: 157px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 28px;
          color: rgba(51, 51, 51, 0.85);
          margin: 3px 0 0 40px;
        }
      }
    }

    // 项目价值
    .contentTwo {
      margin-top: 60px;

      .titleTwo {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }

      .titleT {
        width: 1200px;
        // height: 72px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 28px;
        color: rgba(51, 51, 51, 0.85);
        margin-top: 30px;
        
      }
    }

    // 项目概况
    .contentThree {

      // margin-top: 60px;
      .titleThree {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }

      .imgContent {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        // margin-top: 30px;
        padding: 0;

        li {
          margin-bottom: 30px;
          background-color: #fff;



          .imgBottom {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.85);
            margin-top: 15px;
            text-align: center;
          }
        }
      }
    }
  }
}</style>
  <style lang="less">
  .el-backtop {
    img {
      width: 30px;
      height: 30px;
    }
  }
  </style>
  